import React, {useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Particles from 'react-particles-js';
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

function App() {
  return (
    <div>
      <div className="background-image"></div>
      <Particles params={
        {
          "particles": {
              "number": {
                  "value": 20,
                  "density": {
                      "enable": false
                  }
              },
              "collisions" : {
                "enable" : false
              },
              "size": {
                  "value": 30,
                  "random": true
              },
              "move": {
                  "direction": "bottom",
                  "out_mode": "out"
              },
              "line_linked": {
                  "enable": false
              },
              "shape": {
                "type": "image",
                "image": [
                  {src: "/blossom.png", height: 20, width: 40},
                  {src: "/blossom2.png", height: 40, width: 20},
                  {src: "/blossom3.png", height: 20, width: 40},
                  {src: "/blossom4.png", height: 20, width: 40}
                ]
              }
          },
          "interactivity": {
              "modes": {
                  "remove": {
                      "particles_nb": 10
                  },
                  "slow": {
                    "factor": 3,
                    "radius": 10
                  }
              }
          }
      }} style={{position: 'absolute', left: '0', right: '0', bottom: '0', top: '0', zIndex: '9000'}}/>
      <Container fluid className="App content">
          
          <Row className="justify-content-md-center">
            <Col>
              <h1 className="text-box">Muni</h1>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col>
              <Image fluid src={process.env.PUBLIC_URL + "/MuniAvatar.png"} style={{maxWidth: '400px', minWidth: '0px', borderRadius: '50%'}}/>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col>
              <div className="text-box">
                Hey! Welcome to my site. There's nothing much here.
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col>
              <h2 className="text-box">Social</h2>
            </Col>     
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg='1'>
              <a className="text-box" href="https://github.com/MunifiSense/">GitHub</a>
            </Col>
            <Col xs lg='1'>
              <a className="text-box" href="https://twitter.com/MunifiS">Twitter</a>
            </Col>
            <Col xs lg='1'>
              <a className="text-box" href="https://www.youtube.com/channel/UCIytTb8E9SeCneu4KKtr4cQ">YouTube</a>
            </Col>
            <Col xs lg='1'>
              <a className="text-box" href="https://www.twitch.tv/muni_channel">Twitch</a>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col>
              <h2 className="text-box">My Stuff</h2>
            </Col>         
          </Row>
          <Row className="justify-content-md-center">
            <Col xs lg='2'>
              <a className="text-box" href="https://github.com/MunifiSense/Hangout-Player">Hangout Player (For VRChat)</a>
            </Col>
          </Row>
      </Container>
    </div>
  );
}

export default App;
